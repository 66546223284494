<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Richieste sospese</h3>
            <div class="card-tools">
              <div class="side-by-side">
              </div>
            </div>
          </div>
          <div class="card-body table-responsive p-0">
            <b-container fluid>
              <!-- User Interface controls -->
              <b-row>
                <b-col lg="12" class="my-1">
                  <b-form-group
                    label="Filtra per"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    description=""
                    class="mb-0">
                    <b-form-checkbox-group v-model="filterOn" class="mt-1">
                      <b-form-checkbox value="NomeRichiedente">Nome</b-form-checkbox>
                      <b-form-checkbox value="CognomeRichiedente">Cognome</b-form-checkbox>
                      <b-form-checkbox value="EmailRichiedente">Email</b-form-checkbox>
                      <b-form-checkbox value="TelefonoRichiedente">Telefono</b-form-checkbox>
                      <b-form-checkbox v-if="$gate.Admin()" value="CodiceOrganizzatore">Parita iva</b-form-checkbox>
                      <b-form-checkbox value="Sigillo">Sigillo</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                 </b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filter"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="5" md="6" class="my-1">
                  <b-form-group
                    label="Per page"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                show-empty
                small
                selectable
                stacked="md"
                ref="selectableTable"
                responsive="sm"
                :items="richieste"
                :fields="fields"
                @row-selected="onRowSelected"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
              >
              <template v-slot:cell(actions)="row">
                  <a href class="btn btn-primary btn-sm" @click.prevent="showModal(row.item)">
                    <i class="fas fa-info-circle"></i>
                  </a>
                </template>
              </b-table>
            </b-container>
          </div>
          <div align="center" v-if="totalRows > perPage && perPage != 0 ">
            <b-col sm="7" md="6" class="my-1" align="center">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isDisabled: function() {
      return !this.terms;
    }
  },
  data() {
    return {
      transProps: {
        name: "flip-list"
      },
     fields: [
        {
          key: "NomeRichiedente",
          label: "Nome",
          sortable: true,
          sortDirection: "desc"
        }, 
        {
          key: "CognomeRichiedente",
          label: "Cognome",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "EmailRichiedente",
          label: "Email",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "TelefonoRichiedente",
          label: "Telefono",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "DataOraCreazione",
          label: "Effettuata il",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Dettagli" },
        //{ key: "edit", label: "Conferma" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [50, 100, 150, { key: 0, text: "Tutti" }],
      sortBy: "nomNomeRichiedente",
      sortDesc: true,
      sortDirection: "asc",
      richieste:{},
      filter: null,
      filterOn:{},
      /*filterOn: [
        "NomeRichiedente",
        "CognomeRichiedente",
        "TelefonoRichiedente",
        "EmailRichiedente",
        "TelefonoRichiedente",
        "DataOraCreazione"
      ],*/
      form: new Form({
        id: "",
        nome: "",
        cognome: "",
        email: "",
        telefono: "",
        dataCreazione: "",
      })
    };
  },
  mounted() {
    // Set the initial number of items
    // console.log('pasoooooo component');
  },
  created() {
    if(user.Attivo==0){
      window.location.href = "/setPassword";
    }else{
      this.caricamentoRichieste();
    }
  },
  methods: {
      caricamentoRichieste(){
          this.form.get('api/richiesteSospese').then(({data})=>{
              this.richieste = data;
              this.totalRows = this.richieste.length;
              //console.log('richieste'+this.totalRows);
              }).catch(error=>{
        console.log("Errore nel carimento dei prodotti "+error);
      })
      },
      showModal(richiesta){
        Vue.prototype.$idRichiesta = richiesta.ID;
        //console.log("richiesta id"+richiesta.ID);
        this.$router.push("dettagli");
      },
      onRowSelected(items) {
      this.selected = items;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  }
};
</script>
import Vue from 'vue'
import Router from 'vue-router'


import gate from './gate';
Vue.prototype.$gate = new gate(window.user);

import Dashboard from './views/Dashboard.vue'
import Archiviate from './views/Archiviate.vue'
import Sospese from './views/Sospese.vue'
import Dettagli from './views/Dettagli.vue'
import DettagliArchiviate from './views/DettagliArchiviate.vue'
import AccountOrganizzatori from './views/AccountOrganizzatori.vue'
import SetPassword from './views/SetPassword.vue'
import CheckTitoli from './views/CheckTitoli.vue'
import BuyVoucher from './views/BuyVoucher.vue'
import RiepilogoDay from './views/RiepilogoDay.vue'

  //console.log("Vediamo il gate "+th$gate.isAdmin());
/*let routes = [
    { path: '/dashboard', component: Dashboard },
    { path: '/archiviate', component: Archiviate },
    { path: '/sospese', component: Sospese },
    { path: '/dettagli', component: Dettagli },
    { path: '/dettagliArchiviate', component: DettagliArchiviate },
    { path: '/accountOrganizzatori', component: AccountOrganizzatori },
]

Vue.use(Router)

// 3. Create the router instance
const router = new Router({
    mode: 'history',
    routes,
    // 4. Register routes
})

export default router*/
Vue.use(Router)

// 3. Create the router instance
const router = new Router({
    mode: 'history',
    // 4. Register routes
    routes: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          rule: 'isUser'
		      }
      },
      {
        path: '/riepilogo_day',
        name: 'riepilogo_day',
        component: RiepilogoDay,
        meta: {
          rule: 'isUser'
		      }
      },
      {
        path: '/buy_voucher',
        name: 'buy_voucher',
        component: BuyVoucher,
        meta: {
          rule: 'isUser'
		      }
      },
      {
        path: '/check',
        name: 'check',
        component: CheckTitoli,
        meta: {
          rule: 'isUser'
		      }
      },
      {
        path: '/sospese',
        name: 'sospese',
        component: Sospese,
        meta: {
          rule: 'isUser'
        }
      },
      {
        path: '/setPassword',
        name: 'setPassword',
        component: SetPassword,
      },
      {
        path: '/archiviate',
        name: 'archiviate',
        component: Archiviate,
        meta: {
          rule: 'isUser'
		}
      },
      {
        path: '/dettagli',
        name: 'dettagli',
        component: Dettagli,
        meta: {
           rule: 'isUser'
		}
      },
      {
        path: '/dettagliArchiviate',
        name: 'dettagliArchiviate',
        component: DettagliArchiviate,
        meta: {
          rule: 'isUser'
        }
      },
      {
        path: '/accountOrganizzatori',
        name: 'accountOrganizzatori',
        component: AccountOrganizzatori,
        meta: {
          rule: 'Admin'
        }
      },
    ],
})
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router

export default class gate {

  constructor(user) {
    this.user = user;
  }

  Admin() {
    return this.user.CodiceOrganizzatore === null;
  }

  isUser() {
    return this.user.CodiceOrganizzatore !== null;
  }

}

<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Dashboard</h1>
              </div>
            </div>
          </div>
    <!-- /.container-fluid -->
        </section>
      </div>
      <div class="col-sm-12">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <!-- Default box -->
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Le tue statistiche</h3>
                      <div class="card-tools">
                        <button type="submit" class="btn btn-success btn-sm" v-if="$gate.Admin()" @click.prevent="showModal">CalcoloAdmin</button>
                        <button type="submit" class="btn btn-success btn-sm" @click.prevent="voucherShow">Riepilogo voucher</button>
                        <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                          <i class="fas fa-minus"></i></button>
                        <button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                          <i class="fas fa-times"></i></button>
                      </div>
                  </div>
                  <div class="card-body">
                    <h3 class="profile-username text-center">Email: {{emailUtente}}</h3>
                    <p class="text-muted text-center" v-if="form.CodiceOrganizzatore">Codice ricercato: {{form.CodiceOrganizzatore}}</p>
                    <p class="text-muted text-center" v-if="$gate.isUser()">Partita Iva: {{CodiceOrganizzatore}}</p>
                    <p class="text-muted text-center" v-if="$gate.Admin()">Admin user GOSTEC</p>
                    <ul class="list-group list-group-unbordered mb-3">
                      <li class="list-group-item">
                        <b>Totale richieste sospese</b>
                          <a class="float-right">{{totalRowsSospese}}</a>
                      </li>
                    </ul>
                    <ul class="list-group list-group-unbordered mb-3">
                      <li class="list-group-item">
                        <b>Totale richieste archiviate</b>
                          <a class="float-right">{{totalRowsArchiviate}}</a>
                      </li>
                    </ul>
                    <ul class="list-group list-group-unbordered mb-3">
                      <li class="list-group-item">
                        <b>Numero di voucher emessi</b>
                          <a class="float-right">{{totalRowsVoucher}}</a>
                      </li>
                    </ul>
                    <ul class="list-group list-group-unbordered mb-3">
                      <li class="list-group-item">
                        <b>Totale € voucher emessi</b>
                          <a class="float-right">€ {{totaleVoucher}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div
      class="modal fade"
      id="showSearch"
      tabindex="-1"
      role="dialog"
      aria-labelledby="configModalTitle"
      aria-hidden="true"
      >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Ricerca analitica per oganizzatore</h5><br>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="exampleFormControlInput1">Partita iva(Organizzatore)</label>
              <input
                v-model="form.CodiceOrganizzatore"
                type="text"
                class="form-control"
                name="CodiceOrganizzatore"
                placeholder="Partita iva"
                required
              />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Indietro</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" @click.prevent="search">Avvia il calcolo</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="voucherSearch"
      tabindex="-1"
      role="dialog"
      aria-labelledby="configModalTitle"
      aria-hidden="true"
      >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Riepilogo consumazioni voucher</h5><br>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="riepologo()">
            <div class="modal-body">
              <div class="form-group" v-if="$gate.Admin()">
                <label for="exampleFormControlInput1">Partita iva(Organizzatore)</label>
                <input
                  v-model="formSearch.CodiceOrganizzatore"
                  type="text"
                  class="form-control"
                  name="CodiceOrganizzatore"
                  placeholder="Partita iva"
                  required
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlInput1">Data ricerca</label>
                  <input
                    v-model="formSearch.dataRicerca"
                    type="date"
                    class="form-control"
                    name="dataRicerca"
                    placeholder="Data"
                    required
                    />
                  </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">Vai al riepilogo</button>
                  </div>
                </div>
              </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isDisabled: function() {
      return !this.terms;
    }
  },
  data() {
    return {
      transProps: {
        name: "flip-list"
      },
      CodiceOrganizzatore:"",
      emailUtente:"",
      totalRowsSospese:0,
      totalRowsArchiviate:0,
      voucher:{},
      totalRowsVoucher:0,
      totaleVoucher:0.00,
      searchVoucher:[],
      storico:{},
      formSearch: new Form({
        dataRicerca: "",
        CodiceOrganizzatore: "",
        storico_organizzatore: false,
      }),
      form: new Form({
        id: "",
        nome: "",
        cognome: "",
        email: "",
        telefono: "",
        dataCreazione: "",
        CodiceOrganizzatore: "",
      })
    };
  },
  mounted() {
  },
  created() {
    if(user.Attivo==0){
      window.location.href = "/setPassword";
    }else{
      if(user.CodiceOrganizzatore!==null){
        this.CodiceOrganizzatore=user.CodiceOrganizzatore;
        this.form.CodiceOrganizzatore = this.CodiceOrganizzatore;
      }
      this.form.CodiceOrganizzatore = this.CodiceOrganizzatore;
      this.formSearch.CodiceOrganizzatore = this.CodiceOrganizzatore;
      this.emailUtente = user.Username;
      this.startPage();
    }
  },
  methods: {
    search(){
      this.$Progress.start();
      this.form
          .post("api/richiesteVoucher")
          .then(({ data }) => {
            this.$Progress.finish();
            this.searchVoucher = data;
            this.totalRowsSospese = this.searchVoucher[0].richiesteSospese;
            this.totalRowsArchiviate = this.searchVoucher[0].richiesteArchiviate;
            this.totalRowsVoucher = this.searchVoucher[0].numeroVoucher;
            this.totaleVoucher = Math.round(this.searchVoucher[0].totaleVoucher * 100) / 100;
          })
        .catch(errore => {
          this.$Progress.fail();
        });
    },
    startPage(){
      this.loadRichiesteArchiviate();
      this.loadRichiesteSospese();
      this.loadVocuher();
    },
    voucherShow(){
      this.formSearch.dataRicerca = "";
      Vue.$localStorage.remove('storico_voucher');
      Vue.$localStorage.remove('giorno_riep');
      $("#voucherSearch").modal("show");
    },
    riepologo(){
      console.log("Data ricerca: "+this.formSearch.dataRicerca);
      this.formSearch.put('api/voucherUtilizzi/'+this.formSearch.CodiceOrganizzatore).then(({data})=>{
        if(data=="empty"){
          swal.fire(
                'Organizzatore error!',
                'L\'Organizzatore non ha voucher a suo nome.',
                'error'
              );
              return
        }else if(data == ""){
          swal.fire(
                'Riepilogo inesistente!',
                'Il '+this.formSearch.dataRicerca+" non ci sono state emissioni.",
                'error'
              );
              return
        }else{
          this.storico = data;
          Vue.$localStorage.set('storico_voucher',this.storico);
          Vue.$localStorage.set('giorno_riep',this.formSearch.dataRicerca);
          window.location.href = "/riepilogo_day";
        }
      }).catch(error=>{
        console.log("Errore nel carimento delle richieste "+error);
      });
    },
    loadVocuher(){
      this.totaleVoucher=0.00;
      this.form.get('api/richiesteVoucher').then(({data})=>{
        this.voucher = data;
          this.totalRowsVoucher = this.voucher.length;
          let totale = 0.00;
          for(let i = 0;i<this.totalRowsVoucher;i++){
            totale = totale + Number(this.voucher[i].Importo.replace(/[^0-9.-]+/g,""));
          }
          if(totale>0){
            this.totaleVoucher = Math.round(totale * 100) / 100;
          }
          }).catch(error=>{
          //  console.log("Errore nel carimento dei prodotti "+error);
          })
    },
    loadRichiesteSospese(){
      this.form.get('api/richiesteSospese').then(({data})=>{
          this.totalRowsSospese = data.length;
          }).catch(error=>{
          //  console.log("Errore nel carimento dei prodotti "+error);
          })
    },
    loadRichiesteArchiviate(){
      this.form.get('api/richiesteArchiviate').then(({data})=>{
          this.totalRowsArchiviate = data.length;
          }).catch(error=>{
            //console.log("Errore nel carimento delle richieste "+error);
          })
    },
    showModal(){
      this.form.CodiceOrganizzatore="";
      $("#showSearch").modal("show");
    }
  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Acquisto tramite voucher</h3>
            <div class="card-tools">
              <div class="side-by-side">
                <button type="button" class="btn btn-success btn-sm" @click.prevent="newModal">
                  <i class="fa fa-shopping-cart"></i>
                  Vedi lo storico degli acquisti
                </button>
              </div>
            </div>
          </div>
          <form @submit.prevent="admin ? showStampa() : stampaTitolo()">
            <loading :active.sync="isLoading" />
            <div class="card-body">
              <h1 style="text-align:center;"> IMPORTO SPENDIBILE €{{importoResiduo}} </h1>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="exampleFormControlInput1">Nome Richiedente</label>
                    <input
                      v-model="form.NomeRichiedente"
                      type="text"
                      class="form-control"
                      name="NomeRichiedente"
                      readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Cognome Richiedente</label>
                      <input
                      v-model="form.CognomeRichiedente"
                      type="text"
                      class="form-control"
                      name="CognomeRichiedente"
                      readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Codice Voucher</label>
                      <input
                      v-model="form.CodiceUtilizzo"
                      type="text"
                      class="form-control"
                      name="CodiceUtilizzo"
                    readonly
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="exampleFormControlInput1">Importo iniziale</label>
                    <input
                      v-model="form.Importo"
                      type="text"
                      class="form-control"
                      name="Importo"
                      readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Data emissione voucher</label>
                      <input
                      v-model="form.DataOraEmissione"
                      type="text"
                      class="form-control"
                      name="DataOraEmissione"
                      readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Data scadenza voucher</label>
                      <input
                      v-model="form.DataOraScadenza"
                      type="text"
                      class="form-control"
                      name="DataOraScadenza"
                      readonly
                      />
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">IMPORTO DA INSERIRE €</label>
                      <input
                      v-model="form.importoInserito"
                      type="text"
                      class="form-control"
                      name="importoInserito"
                      placeholder="IMPORTO €€"
                      required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="back">Indietro</button>
                <button type="submit" class="btn btn-success">ACQUISTA</button>
              </div>
            </form>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="configModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="configModalTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Tabella storico</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <b-table striped hover :items="storico" :fields="fields" ref="table" responsive small>
                </b-table>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="emailModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="configModalTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Email organizzatore(Questo account è un account admin)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label for="exampleFormControlInput1">Email per la notifica dell'organizzatore</label>
                    <input
                      v-model="form.emailOrganizzatore"
                      type="email"
                      class="form-control"
                      name="emailOrganizzatore"
                      placeholder="Email di invio notifica organizzatore"
                      required
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                  <button type="submit" class="btn btn-primary" data-dismiss="modal" @click="adminStampa">Invia Mail</button>
                </div>
             </div>
          </div>
        </div>
      </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
computed: {
  isDisabled: function() {
    return !this.terms;
  }
},
components: {
        Loading,
},
data() {
  return {
    transProps: {
      name: "flip-list"
    },
    fields: [
      {
        key: "DataOraRegistrazione",
        label: "DataUtilizzo",
        sortable: true,
        sortDirection: "desc"
      },
      {
        key: "parseImporto",
        label: "Importo",
        sortable: true,
        sortDirection: "desc"
      },
    ],
    importoResiduo:0.00,
    voucher:{},
    storico:{},
    admin:"",
    isUser: 0,
    isLoading: false,
    isSuccess: false,
    fullPage: true,
    storico:{},
    form: new Form({
      ID_Voucher:"",
      NomeRichiedente:"",
      CognomeRichiedente:"",
      CodiceUtilizzo:"",
      EmailRichiedente:"",
      TelefonoRichiedente:"",
      CodiceOrganizzatore:"",
      Importo:"",
      ImportoInserito:"",
      DataOraScadenza:"",
      DataOraEmissione:"",
      importoInserito: "",
      importoFinale: 0.00,
      differenza : 0.00,
      emailOrganizzatore: "",
      storico_organizzatore: true,
    })
  };
},
mounted() {

},
created(){
    if(user.CodiceOrganizzatore == null){
      this.form.emailOrganizzatore = "";
      this.admin = true;
      this.isUser = 0;
    }else{
      this.isUser = 1;
      this.form.emailOrganizzatore = user.Username;
      this.admin = false;
    }
    this.form.CodiceUtilizzo = localStorage.getItem("code_voucher");
    this.utilizzoVocuher();
},
methods: {
  back(){
    this.$router.push("archiviate");
  },
  showStampa(){
    if(!this.controlloImporti()){
      return;
     }
    this.form.emailOrganizzatore="";
    $('#emailModal').modal('show');
  },
  adminStampa(){
     if(this.form.emailOrganizzatore=="" || this.form.emailOrganizzatore==null || !this.form.emailOrganizzatore){
        swal.fire(
                'Email error!',
                'Non è stata inserita alcuna mail.',
                'warning'
              );           
    }else{
      this.isUser=1;
      this.stampaTitolo();
    }
  },
  utilizzoVocuher(){
    this.form.get('api/voucherUtilizzi/'+this.form.CodiceUtilizzo).then(({data})=>{
        this.voucher = data;
        this.importoResiduo = this.voucher[0].importoResiduo;
        this.form.fill(this.voucher[0]);
        if(this.importoResiduo<=0){
          swal.fire(
                'Voucher esaurito!',
                'Per maggiori info controllare lo storico.',
                'warning'
              );
        }
        }).catch(error=>{
          console.log("Errore nel carimento delle richieste "+error);
        });
  },
  newModal(){
    if(this.importoResiduo==this.form.Importo){
      swal.fire(
            'Storico vuoto',
            'Non è stato mai fatto un acquisto con questo voucher.',
            'warning'
          );
          return;
    }
    this.form.put('api/voucherUtilizzi/'+this.form.ID_Voucher).then(({data})=>{
      this.storico = data;
      $('#configModal').modal('show');
    }).catch(error=>{
      console.log("Errore nel carimento delle richieste "+error);
    });
  },
  controlloImporti(){
    if(this.form.importoInserito.trim()=='' || this.form.importoInserito==null || this.form.importoInserito == "0" || this.form.importoInserito =="0,00" ||
        this.form.importoInserito == "0.0" || this.form.importoInserito == "0,0" || this.form.importoInserito == "00.00" || this.form.importoInserito == "0.00"){
          swal.fire(
                'Importo non valido!',
                'Controlla l\'importo inserito',
                'warning'
              );
              return false;
        }else{
          var importo = 0.00;
          var app = this.form.importoInserito.replace(/ /g,'');
          var str = app.split("");
          var nStr = str.length;
          this.form.importoInserito = "";
          if(nStr<=2){
            importo = parseFloat(app);
            this.form.importoInserito = app;
          }else if(nStr==3){
            for(var i = 0;i<nStr;i++){
              if(str[i]==","){
                str[i]=".";
              }
              this.form.importoInserito = this.form.importoInserito + str[i];
            }
            importo = parseFloat(this.form.importoInserito);
          }else if(nStr>=4){
            try {
              if(str[nStr-3]==","){
                str[nStr-3]=".";
              }
              for(var i = 0;i<nStr;i++){
                this.form.importoInserito = this.form.importoInserito + str[i];
              }
              importo = parseFloat(this.form.importoInserito);
            } catch (e) {
              this.isLoading = false;
              this.$Progress.fail();
              swal.fire(
                  'Importo non valido!',
                  'Controlla l\'importo inserito',
                  'warning'
                );
                return false;
              }
          }
          if(importo<=0){
            swal.fire(
                  'Importo non valido!',
                  'Controlla l\'importo inserito',
                  'warning'
                );
            return false;
          }
          this.form.differenza = this.importoResiduo - importo;
          this.form.importoFinale = importo;
          if(this.form.differenza<0){
            swal.fire(
                'Importo non valido!',
                'L\'importo inserito è maggiore di quello residuo.',
                'warning'
              );
              return false;
          }
        return true;
      }
  },
  stampaTitolo(){
    this.$Progress.start();
    this.isLoading = true;
    this.isSuccess = false;
    if(this.isUser==2 || this.isUser==1){
      if(!this.admin){
        this.form.emailOrganizzatore = user.Username;
      }
      if(this.form.importoInserito.trim()=="" || this.form.importoInserito==null || this.form.importoInserito == "0" || this.form.importoInserito =="0,00" ||
          this.form.importoInserito == "0.0" || this.form.importoInserito == "0,0" || this.form.importoInserito == "00.00" || this.form.importoInserito == "0.00"){
        this.$Progress.fail();
        this.isSuccess = false;
        this.isLoading = false;
        swal.fire(
              'Importo non valido!',
              'Controlla l\'importo inserito.',
              'warning'
            );
        return;
      }else{
          var importo = 0.00;
          var app = this.form.importoInserito.replace(/ /g,'');
          var str = app.split("");
          var nStr = str.length;
          this.form.importoInserito = "";
          if(nStr<=2){
            importo = parseFloat(app);
          }else if(nStr==3){
            for(var i = 0;i<nStr;i++){
              if(str[i]==","){
                str[i]=".";
              }
              this.form.importoInserito = this.form.importoInserito + str[i];
            }
            importo = parseFloat(this.form.importoInserito);
          }else if(nStr>=4){
            try {
              if(str[nStr-3]==","){
                str[nStr-3]=".";
              }
              for(var i = 0;i<nStr;i++){
                this.form.importoInserito = this.form.importoInserito + str[i];
              }
              importo = parseFloat(this.form.importoInserito);
            } catch (e) {
              this.isLoading = false;
              this.$Progress.fail();
              swal.fire(
                  'Importo non valido!',
                  'Controlla l\'importo inserito.',
                  'warning'
                );
                return;
              }
          }
          if(importo<=0){
            this.$Progress.fail();
            this.isLoading = false;
            swal.fire(
                  'Importo non valido!',
                  'Controlla l\'importo inserito.',
                  'warning'
                );
            return;
          }
          this.form.differenza = this.importoResiduo - importo;
          this.form.importoFinale = importo;
          if(this.form.differenza<0){
            this.$Progress.fail();
            this.isLoading = false;
            swal.fire(
                'Importo non valido!',
                'L\'importo inserito è maggiore di quello residuo. ',
                'warning'
              );
              return;
        }
        this.form.post('api/voucherUtilizzi').then(({data})=>{
          if(data=="scaduto"){
            swal.fire(
                'Voucher scaduto!',
                'Il codice del voucher è scaduto.',
                'error'
              );
              this.$Progress.finish();
              this.isSuccess = true;
              this.isLoading = false;
              return;
          }else{
            this.$Progress.finish();
            this.isSuccess = true;
            this.isLoading = false;
            swal.fire(
                'Acquisto effettuato',
                'Abbiamo inviato le mail di conferma.',
                'success'
              );
            this.$router.push("archiviate");
          }
        }).catch(error=>{
          console.log("Errore nel carimento delle richieste "+error);
        });
      }
    }
  }
}
};
</script>

<template>
  <div class="container" v-if="appoggioUtente==0">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Set password (Organizzatore)</h3>
          </div>
          <form @submit.prevent="setPassword()">
            <div class="card-body">
              <b-container fluid>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Password</label>
                      <input
                        v-model="form.Password"
                        type="password"
                        class="form-control"
                        name="Password"
                        placeholder="Password"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Confirm password</label>
                      <input
                        v-model="form.PasswordConfirm"
                        type="password"
                        class="form-control"
                        name="Password"
                        placeholder="Conferma password"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Aggiorna password</button>
                </div>
              </b-container fluid>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isDisabled: function() {
      return !this.terms;
    }
  },
  data() {
    return {
      transProps: {
        name: "flip-list"
      },
      appoggioUtente:"",
      form: new Form({
        Username: "",
        Password: "",
        Attivo: "",
        CodiceOrganizzatore: "",
        ID: "",
        PasswordConfirm:"",
      })
    };
  },
  mounted() {
    this.appoggioUtente = user.Attivo;
  },
  created() {
  },
  methods: {
    setPassword(){
      if(this.form.Password == this.form.PasswordConfirm && this.form.Password != "" && this.form.PasswordConfirm != ""){
        this.form.ID = user.ID;
        this.form.CodiceOrganizzatore=user.codiceOrganizzatore;
        this.$Progress.start();
        this.form
          .put("api/users/" +user.ID)
          .then(() => {
            this.$Progress.finish();
            swal.fire({
              title:"Aggiornato!",
              text:"Password aggiornata.",
              type:"success",
              showCancelButton: false,
              confirmButtonText: "Ok",
            }).then(result=>{
               user.Attivo = 1;
               window.location.href = "/sospese";
            });
          })
          .catch(() => {
            this.$Progress.fail();
          });
      }else{
        swal.fire(
                "Account error!",
                "Errore nella password",
                "error"
              );
      }
    }
  }
};
</script>

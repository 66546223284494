<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Account organizzatori</h3>
            <div class="card-tools">
              <div class="side-by-side">
                <div class="side-by-side">
                  <button type="submit" class="btn btn-success btn-sm"  v-if="$gate.Admin()" @click.prevent="newModal">
                    <i class="fas fa-user-plus fa-fw"></i>
                      Aggiungi organizzatore</button>
              </div>
            </div>
          </div>
          <div class="card-body table-responsive p-0">
            <b-container fluid>
              <!-- User Interface controls -->
              <b-row>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filter"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="5" md="6" class="my-1">
                  <b-form-group
                    label="Per page"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                show-empty
                small
                selectable
                stacked="md"
                ref="selectableTable"
                responsive="sm"
                :items="users"
                :fields="fields"
                @row-selected="onRowSelected"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
              >
              <template v-slot:cell(actions)="row">
                  <!--<a href class="btn btn-primary btn-sm" @click.prevent="showModal(row.item)">
                    <i class="fas fa-info-circle"></i>
                  </a>-->
                  <a href class="btn btn-primary btn-sm" v-if="$gate.Admin()" @click.prevent="editModal(row.item)">
                    <i class="fas fa-edit"></i>
                  </a>
                  <a href class="btn btn-danger btn-sm" v-if="$gate.Admin()" @click.prevent="deleteConfig(row.item)">
                    <i class="fas fa-trash"></i>
                  </a>
                </template>
                </b-table>
            </b-container>
          </div>
          <div align="center" v-if="totalRows > perPage && perPage != 0 ">
            <b-col sm="7" md="6" class="my-1" align="center">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
      class="modal fade"
      id="configModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="configModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-show="editMode" class="modal-title" id="exampleModalLongTitle">Aggiorna organizzatore</h5>
            <h5 v-show="!editMode" class="modal-title" id="exampleModalLongTitle">Nuovo organizzatore</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="editMode ? updateConfig() : createConfig()">
            <div class="modal-body">
              <h8 class="modal-title" id="exampleModalLongTitle">La password viene generata sia in creazione che in modifica</h8>
              <div class="form-group">
                <label for="exampleFormControlInput1">Email</label>
                <input
                  v-model="form.Username"
                  type="email"
                  class="form-control"
                  name="Username"
                  placeholder="Email"
                  required
                />
              </div>
              <div class="form-group" v-show="!editMode">
                <label for="exampleFormControlInput1">Partita iva</label>
                <input
                  v-model="form.CodiceOrganizzatore"
                  type="text"
                  class="form-control"
                  name="CodiceOrganizzatore"
                  placeholder="Partita iva"
                  required
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Annulla</button>
              <button v-show="editMode" type="submit" class="btn btn-primary">Aggiorna</button>
              <button v-show="!editMode" type="submit" class="btn btn-success">Crea</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isDisabled: function() {
      return !this.terms;
    }
  },
  data() {
    return {
      transProps: {
        name: "flip-list"
      },
      fields: [
        {
          key: "Username",
          label: "Username",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "CodiceOrganizzatore",
          label: "Partita iva",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "Attivo",
          label: "Attivo",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Dettagli" },
      ],
      totalRows: 1,
      totalRowsUsers:1,
      users:{},
      currentPage: 1,
      perPage: 25,
      pageOptions: [50, 100, 150, { key: 0, text: "Tutti" }],
      sortBy: "nome",
      sortDesc: true,
      sortDirection: "asc",
      editMode: "",
      form: new Form({
        Username: "",
        Password: "",
        Attivo: "",
        CodiceOrganizzatore: "",
        ID: "",
      })
    };
  },
  mounted() {
    // Set the initial number of items
    // console.log('pasoooooo component');
  },
  created() {
    if(user.CodiceOrganizzatore===null){
      this.loadUser();
      Fire.$on("UpdateList", () => {
        this.loadUser();
      });
    }
  },
  methods: {
    loadUser(){
      console.log("Entro nel load user");
      this.form.get('api/users').then(({data})=>{
        this.users = data;
        //console.log("Entro nel metodo "+this.users[0].Attivo);
        this.totalRowsUsers = this.users.lenght;
      }).catch(error=>{
        console.log("Errore nel carimento delle richieste "+error);
      })
    },
    newModal(){
      this.editMode = false;
      this.form.reset();
      $("#configModal").modal("show");
    },
    createConfig(){
      if(user.CodiceOrganizzatore===null){
        this.$Progress.start();
        this.form.post("api/users")
          .then(({ data }) => {
            if(data==1){
              $("#configModal").modal("hide");
              Fire.$emit("UpdateList");
              this.$Progress.finish();
              swal.fire({
                type: "success",
                title: "L'organizzatore è stato creato."
              });
            }else{
              swal.fire(
                      "Account error!",
                      "L'account o la partita iva gia è esistente",
                      "error"
                    );
            }
          })
          .catch(errore => {
            this.$Progress.fail();
          });
        }
    },
    editModal(user){
      user.Password="";
      this.editMode = true;
      this.form.reset();
      this.form.fill(user);
      $("#configModal").modal("show");
    },
    updateConfig(){
        this.$Progress.start();
        this.form.Attivo = false;
        this.form
          .put("api/users/" +this.form.ID)
          .then(({data}) => {
            console.log("Vediamo cosa risponde"+data);
            if(data==1){
              $("#configModal").modal("hide");
              Fire.$emit("UpdateList");
              this.$Progress.finish();
              swal.fire(
                "Aggiornato!",
                "L'organizzatore è stato aggiornato.",
                "success"
              );
            }else{
              swal.fire(
                "Error!",
                "Account già utilizzato.",
                "error"
              );
            }
          })
          .catch(() => {
            this.$Progress.fail();
          });
    },
    deleteConfig(user) {
        swal
          .fire({
            title: "Sei sicuro?",
            text: "Questa operazione non è reversibile!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, voglio eliminarlo!",
            cancelButtonText: "Annulla"
          })
          .then(result => {
            if (result.value) {
              this.form
                .delete("api/users/" + user.ID)
                .then(() => {
                  Fire.$emit("UpdateList");
                  swal.fire("Eliminato!", "Organizzatore eliminato!", "success");
                })
                .catch(e => {
                  swal.fire(
                    "Errore!",
                    "Non è stato possibile eliminare l'organizzatore.",
                    "error"
                  );
                });
              }
            });
    },
  }
};
</script>

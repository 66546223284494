window.Vue = require('vue');

import PortalVue from 'portal-vue'
import BootstrapVue from 'bootstrap-vue'
import autofocus from "vue-autofocus-directive";
import StoragePlugin from 'vue-web-storage';
// Bootstrap vue

Vue.use(PortalVue)
Vue.use(BootstrapVue)
Vue.use(StoragePlugin); 


//progress bar
import VueProgressBar from 'vue-progressbar'

// Importo axios
import axios from 'axios'
import VueAxios from 'vue-axios'
// Gate
import gate from './gate';
Vue.prototype.$gate = new gate(window.user);
//importo Vue route
import router from './router';
window.Fire = new Vue();

import Vue from 'vue'
import VueZoomer from 'vue-zoomer'

Vue.use(VueZoomer)

// VForm
import { Form, HasError, AlertError } from 'vform';
window.Form = Form;
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);

Vue.use(VueAxios, axios)

Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '10px'
})



// ES6 Modules or TypeScript
import swal from 'sweetalert2';
window.swal = swal;
const toast = swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
});

window.toast = toast;
// Pagination
Vue.component('pagination', require('laravel-vue-pagination'));





const app = new Vue({
  el: '#app',
  router,
});

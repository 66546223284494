<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Dettagli della richiesta</h3>
            <div class="card-tools">
              <div class="side-by-side">
                  <button type="submit" class="btn btn-success btn-sm" @click.prevent="controlCheck">
                    Check di controllo</button>
		   <a
                      :href="'Voucher.pdf'"
                      :target="'Voucher.pdf'"
                      rel="noopener noreferrer">Voucher_PDF</a>
              </div>
            </div>
          </div>
          <form>
            <div class="card-body">
              <b-container fluid>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Nome</label>
                      <input
                        v-model="formRichiesta.NomeRichiedente"
                        type="text"
                        class="form-control"
                        name="NomeRichiedente"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Cognome</label>
                      <input
                        v-model="formRichiesta.CognomeRichiedente"
                        type="text"
                        class="form-control"
                        name="TelefonoRichiedente"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Telefono</label>
                      <input
                        v-model="formRichiesta.TelefonoRichiedente"
                        type="text"
                        class="form-control"
                        name="TelefonoRichiedente"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Email</label>
                      <input
                        v-model="formRichiesta.EmailRichiedente"
                        type="text"
                        class="form-control"
                        name="EmailRichiedente"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="side-by-side">
                    <button type="button" class="btn btn-primary btn-sm" v-if="$gate.Admin()" @click.prevent="showPhoto">Galleria foto</button>
                  </div>
                    <b-table striped hover :items="log" :fields="fields" ref="table" responsive small>

                      <template v-slot:cell(esito)="row">
                          <div v-show="row.item.esito==true">
                            <i class="fas fa-check-circle" style="color:green"></i>
                          </div>
                          <div v-show="row.item.esito==false">
                            <i class="fas fa-times-circle" style="color:red"></i>
                            <button type="button" class="btn btn-danger" @click.prevent="showErrors(row.item)">ShowErrors</button>
                          </div>
                          ||
                          <div v-show="row.item.tipologiaBiglietto==true">
                            <i class="fas fa-camera" style="color:#42A5F5"></i>
                          </div>
                        </template>
                        <template v-slot:cell(eventiAbilitati)="row">
                          <div v-show="row.item.eventiAbilitati!=0">
                            <p>{{row.item.eventiAbilitati}}</p>
                            <div v-show="row.item.tipoTurno=='L' && $gate.Admin()">
                              <i class="fas fa-edit" @click.prevent="showOpenEdit(row.item)"></i>
                            </div>
                          </div>
                        </template>
                        <template v-slot:cell(ivaPreassolta)="row">
                          <div v-show="row.item.ivaPreassolta=='N'">
                            <p>Non preassolta</p>
                        </div>
                        <div v-show="row.item.ivaPreassolta=='B' || row.item.ivaPreassolta=='F'">
                          <p>Preassolta</p>
                        </div>
                        </template>
                        <!--<template v-slot:cell(foto)="row">
                            <div v-show="row.item.tipologiaBiglietto==true">
                              <i class="fas fa-camera" style="color:#42A5F5"></i>
                            </div>
                          </template>-->
                  </div>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="back">Indietro</button>
                  <button type="button" class="btn btn-danger" v-if="$gate.Admin()" @click.prevent="showEmail">Respingi la richiesta</button>
                  <button type="submit" class="btn btn-primary" v-if="$gate.Admin()" @click.prevent="sendMail">Invia mail</button>
                </div>
              </b-container>
            </div>
          </form>
        </div>
      </div>
      <div
        class="modal fade"
        id="editOpenImporto"
        tabindex="-1"
        role="dialog"
        aria-labelledby="configModalTitle"
        aria-hidden="true"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Modifica importo open</h5><br>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <!--<div class="checkbox">
                <label><input type="checkbox" id="mycheckbox" value="" @change="onChange($event)"> Rateo pari a 0.00€ (Se si è sicuri che la differenza sarà 0 per)</label>
              </div>-->
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">€</span>
                </div>
                <input type="number" min="0.01" max="10000.00" step="0.01" class="form-control" v-model="importoOpen" aria-label="Amount (to the nearest dollar)">
              </div>
              <b-form-textarea
                id="textarea-state"
                v-model="testoOpen"
                :state="testoOpen.length >= 10"
                placeholder="Motivazione della modifica"
                rows="3"
                ></b-form-textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal" @click.prevent="openUpdate">Conferma</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Indietro</button>
            </div>
        </div>
      </div>
    </div>
      <div
        class="modal fade"
        id="showEmailAdmin"
        tabindex="-1"
        role="dialog"
        aria-labelledby="configModalTitle"
        aria-hidden="true"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Rifiuto della richiesta</h5><br>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <b-form-textarea
                id="textarea-state"
                v-model="text"
                :state="text.length >= 10"
                placeholder="10 caratteri obbligatori"
                value="log.info"
                rows="3"
                ></b-form-textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal" @click.prevent="respingiRichieste">Conferma</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Annulla</button>
            </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="showPhoto"
      tabindex="-1"
      role="dialog"
      aria-labelledby="configModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Galleria biglietti</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <div>
                <ul>
                  <li v-for="(msg,index) in pdf"> <a
                    :href="msg"
                    :target="msg"
                    rel="noopener noreferrer">
                    pdf_{{index+1}}
                  </a></li>
                </ul>
              </div>
                <div class="widget-user-image" v-show="foto.length > 0">
                  <p v-show="foto.length > 1">
                      <a @click="prev"><i class="fas fa-arrow-left"></i></a> || <a @click="next"><i class="fas fa-arrow-right"></i></a>
                  </p>
                  <v-zoomer style="width: 100%; height: 100%; border: solid 1px silver;">
                    <img class="img-thumbnail" :src="foto[iFoto % foto.length]" alt="Foto_Cartaceo" id="myImg" style="object-fit: contain; width: 100%; height: 100%;">
                   </v-zoomer>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Indietro</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isDisabled: function() {
      return !this.terms;
    }
  },
  data() {
    return {
      transProps: {
        name: "flip-list"
      },
      fields: [
        {
          key: "Sigillo",
          label: "Sigillo",
          sortable: true,
          _showDetails: false,
          sortDirection: "desc"
        },
        {
          key: "tipoEvento",
          label: "Evento",
          sortable: true,
          _showDetails: false,
          sortDirection: "desc"
        },
        {
          key: "data",
          label: "Data",
          sortable: true,
          _showDetails: false,
          sortDirection: "desc"
        },
        {
          key: "codiceOrganizzatore",
          label: "Organizzatore",
          sortable: true,
          _showDetails: false,
          sortDirection: "desc"
        },
        {
          key: "corrispettivoLordo",
          label: "Importo",
          sortable: true,
          _showDetails: false,
          sortDirection: "desc"
        },
        {
          key: "rateo",
          label: "Rateo",
          sortable: true,
          _showDetails: false,
          sortDirection: "desc"
        },
        {
          key: "eventiAbilitati",
          label: "N_eventi",
          sortable: true,
          _showDetails: false,
          sortDirection: "desc"
        },
        {
          key: "tipoSigillo",
          label: "TipoSig",
          sortable: true,
          _showDetails: false,
          sortDirection: "desc"
        },
        {
          key:"ivaPreassolta",
          label:"IVA",
          sortable: true,
          _showDetails: false,
          sortDirection: "desc"
        },
        {
          key: "locale",
          label: "Locale",
          sortable: true,
          _showDetails: false,
          sortDirection: "desc"
        },
        { key: "esito", label: "risultato" },
        /*{ key: "foto", label: "Dig/Cart"}*/
      ],
      totalRowsSigilli: 1,
      totalRowsTitoli: 1,
      richiesteTitoli:{},
      contatore:0,
      contFoto:0,
      text:'',
      importoOpen:0.01,
      testoOpen:'',
      richiesta:{},
      formRichiesta:new Form({
        NomeRichiedente: "",
        CognomeRichiedente: "",
        EmailRichiedente: "",
        TelefonoRichiedente: "",
        DataOraCreazione: "",
        ID: "",
        contatoreFoto:"",
      }),
      formTitoli:new Form({
        ID: "",
        Sigillo: "",
        ID_Richiesta: "",
        CodiceOrganizzatore: "",
      }),
      formLog: new Form({
        SIG_FISC:"",
        TAED_DATA_EVENTO:"",
        SPETT_O_INT:"",
      }),
      log:[],
      dataMail:{},
      foto:[],
      appFoto:[],
      pdf:[],
      logAppoggio:[],
      iFoto:0,
      erroriMail:[],
      sigilloOpen:'',
      importoNull:false,
    };
  },
  mounted() {
  },
  created() {
    if (typeof this.$idRichiesta !== 'undefined'){
        localStorage.setItem("idRichiestaSospesa",this.$idRichiesta);
    }else{
      this.$idRichiesta = localStorage.getItem("idRichiestaSospesa");
    }
    this.loadNewData();
  },
  methods: {
    onChange(event){
      if(this.importoNull){
        this.importoNull=false;
      }else{
        this.importoNull=true;
      }
    },
    showEmail(){
      if(this.contatore==0){
        swal.fire(
                "Annullamento error!",
                "Non puoi annullare la richiesta senza prima fare il check!",
                "error"
              );
      }else{
        $("#showEmailAdmin").modal("show");
      }
    },
    respingiRichieste(){
      for(var i = 0;i<this.totalRowsTitoli;i++){
        if(this.log[i].esito==true){
          this.log[i].esito=false;
        }
        this.log[i].info='';
        this.log[i].info=this.text;
        console.log("Verifica annullo "+this.log[i].info);
      }
      this.$refs.table.refresh();
      this.sendMail();
    },
    next: function() {
            this.iFoto += 1;
        },
        prev: function() {
            this.iFoto -= 1;
        },
    back(){
      this.$router.push("sospese");
    },
    showPhoto(sigillo){
      if(this.contatore==0){
        swal.fire(
                "Foto error!",
                "Non hai fatto il check delle richieste!",
                "error"
              );
      }else{
        if(this.contFoto==0){
          swal.fire(
                  "Foto error!",
                  "Non sono state caricate foto nella lista!",
                  "error"
                );
        }else{
          this.foto=[];
          this.pdf=[];
          this.appFoto=[];
            this.formRichiesta.contatoreFoto = this.contFoto;
            this.formRichiesta.post('api/richiesteSospese/').then(({data})=>{

              this.appFoto = data;
              for(let i = 0;i<this.appFoto.length;i++){
              let stringa = this.appFoto[i].split(".");
              let lunghezza = stringa.length;
              let estensione = stringa[lunghezza-1];
              if(estensione=='pdf' || estensione=='PDF'){
                this.pdf.push(this.appFoto[i]);
               }else{
                this.foto.push(this.appFoto[i]);
               }
            }
            }).catch(error=>{
              console.log("Errore nel carimento delle foto "+error);
            });
            $("#showPhoto").modal("show");
        }
      }
    },
    showOpenEdit(sigillo){
      if(this.contatore==0){
        swal.fire(
                "Annullamento error!",
                "Non puoi annullare la richiesta senza prima fare il check!",
                "error"
              );
      }else{
        if(sigillo.info===''){
          this.sigilloOpen='';
          this.testoOpen='';
          this.importoOpen=0.01;
        }else{
          for(let i = 0;i<this.totalRowsTitoli;i++){
            if(this.log[i].Sigillo==sigillo.Sigillo){
              this.importoOpen=this.log[i].rateoDaTogliere;
              this.testoOpen=this.log[i].info;
            }
          }
        }
        //this.importoNull=false;
        //document.getElementById("mycheckbox").checked = false;
        this.sigilloOpen=sigillo.Sigillo;
        $("#editOpenImporto").modal("show");
      }
    },
    openUpdate(){
      if(this.importoOpen>0.00){
        for(let i = 0;i<this.totalRowsTitoli;i++){
          if(this.sigilloOpen==this.log[i].Sigillo){
            this.log[i].info='';
            this.log[i].info=this.testoOpen;
            let importo = this.log[i].eventiAbilitati*this.log[i].rateo;
            let totale = importo-this.importoOpen;
            this.log[i].rateoTotale = totale;
            this.log[i].rateoDaTogliere=this.importoOpen;
            if(totale<=0){
              this.log[i].esito=false;
            }else{
              this.log[i].esito=true;
            }
            this.$refs.table.refresh();
          }
        }
      }else{
        swal.fire(
                "Importo error!",
                "Non puoi inserire un valore inferiore a 0!",
                "error"
              );
      }
    },
    showErrors(sigillo){
      if(sigillo.info==''){
        swal.fire(
                "Info error!",
                this.log.info,
                "error"
              );
      }else{
        swal.fire(
                "Info error!",
                sigillo.info,
                "error"
              );
      }
    },
    loadNewData(){
        this.loadRichiesta();
        this.loadTitoli();
    },
    sendMail(){
      if(user.CodiceOrganizzatore===null){
        if(this.contatore==0){
          swal.fire(
                  "Email error!",
                  "Non hai fatto il check delle richieste!",
                  "error"
                );
        }else{
        swal
          .fire({
            title: "Confermi l'invio della mail?",
            text: "Questa operazione non è reversibile!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, inviala!",
            cancelButtonText: "Annulla",
          }).then(result => {
            if (result.value) {
            var x = [];
                  for(var i = 0;i<this.totalRowsTitoli;i++){
                    this.dataMail = new Object();
                    this.dataMail.sigillo= this.log[i].Sigillo;
                    if(this.logAppoggio[i].Sigillo==this.log[i].Sigillo){
                      this.dataMail.idRichiestaTitoli = this.logAppoggio[i].ID;
                    }else{
                      for(let j = 0;j<this.totalRowsTitoli;j++){
                        if(this.log[i].Sigillo==this.logAppoggio[j].Sigillo){
                          this.dataMail.idRichiestaTitoli = this.logAppoggio[j].ID
                        }
                      }
                    }
                    this.dataMail.codiceOrganizzatore = this.log[i].codiceOrganizzatore;
                    this.dataMail.locale = this.log[i].Locale;
                    this.dataMail.info = this.log[i].info;
                    this.dataMail.dataCreazione = this.formRichiesta.DataOraCreazione;
                    this.dataMail.importo = this.log[i].corrispettivoLordo;
                    this.dataMail.data = this.log[i].data;
                    this.dataMail.tipoEvento = this.log[i].tipoEvento;
                    this.dataMail.esito = this.log[i].esito;
                    this.dataMail.rateo = this.log[i].rateo;
                    this.dataMail.nomeOrganizzatore=this.log[i].nomeOrganizzatore;
                    this.dataMail.partitaIva = this.log[i].partitaIva;
                    this.dataMail.eventiAbilitati = this.log[i].eventiAbilitati;
                    this.dataMail.tipoTurno = this.log[i].tipoTurno;
                    this.dataMail.ID = this.log[i].ID;
                    this.dataMail.tipoSigillo = this.log[i].tipoSigillo;
                    this.dataMail.nome = this.richiesta[0].NomeRichiedente;
                    this.dataMail.cognome = this.richiesta[0].CognomeRichiedente;
                    this.dataMail.email = this.richiesta[0].EmailRichiedente;
                    this.dataMail.idCliente = this.formRichiesta.ID;
                    this.dataMail.idGeoTeatri = this.log[i].idGeoTeatri;
                    this.dataMail.idAutClienti = this.log[i].idAutClienti;
                    this.dataMail.locale = this.log[i].locale;
                    this.dataMail.importoOpen=this.log[i].rateoTotale;
                    this.dataMail.rateoDaTogliere=this.log[i].rateoDaTogliere;
                    x.push(this.dataMail);
                  }
                  axios.post('api/controlloVoucher/', x).then(function (response) {
                      swal.fire({
                        title:"Iniviata!",
                        text:"Email inviata!",
                        type:"success",
                        showCancelButton: false,
                        confirmButtonText: "Ok",
                      }).then(result=>{
                         window.location.href = "/sospese";
                      });
                      }).catch(function (error) {
                        console.log("errore resposnse"+error);
                        swal.fire(
                                "Email error!",
                                "Si è verificato un errore!",
                                "error"
                              );
                        });
                      }
            });
          }
        }
      },
    loadRichiesta(){
        this.formRichiesta.get('api/richiesteSospese/'+this.$idRichiesta).then(({data})=>{
        this.richiesta = data;
        this.formRichiesta.fill(this.richiesta[0]);
              }).catch(error=>{
        console.log("Errore nel carimento della richiesta "+error);
      });
    },
    loadTitoli(){
      this.richiesteTitoli = {};
        this.formTitoli.get('api/richiesteTitoli/'+this.$idRichiesta).then(({data})=>{
            this.richiesteTitoli=data;
            this.totalRowsTitoli = this.richiesteTitoli.length;
            for(var i = 0; i < this.totalRowsTitoli; i++){
              this.log.push(this.richiesteTitoli[i]);
              this.logAppoggio.push(this.richiesteTitoli[i]);
            }
        }).catch(error=>{
        console.log("Errore nel carimento della richiesta titoli "+error);
      });
    },
    controlCheck(){
      this.contatore++;
      this.log=[];
      this.contFoto=0;
        this.$Progress.start();
        for(let i = 0;i<this.totalRowsTitoli;i++){
          this.formTitoli.get('api/controlloVoucher/'+this.richiesteTitoli[i].Sigillo).then(({data})=>{
            if(data.tipologiaBiglietto==true){
              this.contFoto++;
            }
            this.log.push(data);
          }).catch(error=>{
            console.log("Vediamo se vado in errore nel voucher"+error);
              this.$Progress.fail();
          });
        }
        let controlloTitoli=true;
        let cont=0;
        for(let j = 0;j<this.totalRowsTitoli;j++){
          if(this.log[j]==true){
            cont++;
            if(cont==1){
              let tipoRichiesta = this.log[j].tipoSigillo;
            }
            if(tipoRichiesta!=this.log[j].tipoSigillo){
              controlloTitoli=false;
            }
          }
        }
        if(controlloTitoli==false && cont!=0){
          for(let k = 0;k<this.totalRowsSigilli;k++){
            this.log[k].esito=false;
            this.log[k].info='Sigilli di tipologia diversa';
          }
        }
        this.$Progress.finish();
        this.$refs.table.refresh();
    },
  }
}

</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Check controllo</h3>
            <div class="card-tools">
              <div class="side-by-side">
              </div>
            </div>
          </div>
          <div class="card-body table-responsive p-0">
            <b-container fluid>
              <b-table striped hover :items="log" :fields="fields" ref="table" responsive small>
              </b-table>
            </b-container fluid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isDisabled: function() {
      return !this.terms;
    }
  },
  data() {
    return {
      transProps: {
        name: "flip-list"
      },
      fields: [
        {
          key: "Sigillo",
          label: "Sigillo non annullato",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "CognomeRichiedente",
          label: "Cognome",
          sortable: true,
          sortDirection: "desc"
        },
      ],
      log:[],
      form: new Form({
        NomeRichiedente: "",
        CognomeRichiedente: "",
        EmailRichiedente: "",
        TelefonoRichiedente: "",
        DataOraArchiviazione: "",
        ID: "",
      })
    };
  },
  mounted() {
    // Set the initial number of items
    // console.log('pasoooooo component');
  },
  created() {
    this.searchError();
  },
  methods: {
    searchError(){
      this.form.get('api/controlloVoucher').then(({data})=>{
        this.log = data;
      }).catch(error=>{
      console.log("Errore nel carimento dei titoli "+error);
    })
    },
  }
};
</script>

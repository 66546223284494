<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Riepilogo voucher del {{giorno_riep}}</h1>
              </div>
            </div>
          </div>
    <!-- /.container-fluid -->
        </section>
      </div>
      <div class="col-sm-12">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <h3 class="profile-username text-center">Totale: {{totale}}</h3>
                <b-table striped hover :items="voucher" :fields="voucherFields" ref="tableVoucher">
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isDisabled: function() {
      return !this.terms;
    }
  },
  data() {
    return {
      transProps: {
        name: "flip-list"
      },
      voucherFields:[
        {
          key: "NomeRichiedente",
          label: "Nome",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "CognomeRichiedente",
          label: "Cognome",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "EmailRichiedente",
          label: "Email",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "CodiceUtilizzo",
          label:"Codice utilizzo",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "Importo",
          label:"Importo iniziale",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "ImportoSpeso",
          label:"Importo speso",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "DataOraScadenza",
          label:"Scadenza voucher",
          sortable: true,
          sortDirection: "desc"
        },
      ],
      voucher:{},
      giorno_riep: 0,
      totale: "",
    };
  },
  mounted() {
  },
  created() {
    if(user.Attivo!=0){
      this.giorno_riep = new Date(Vue.$localStorage.get('giorno_riep')).toLocaleDateString("it");
      this.voucher = Vue.$localStorage.get('storico_voucher');
      this.loadTable();
    }else{
      window.location.href = "/setPassword";
    }
  },
  methods: {
    loadTable(){
      let somma = 0.00;
      for(var i=0;i<this.voucher.length;i++){
        somma = somma + parseFloat(this.voucher[i].ImportoSpeso);
      }
      this.totale = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(somma);
    },
  }
}
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Richieste archiviate</h3>
            <div class="card-tools">
              <div class="side-by-side">
                <button type="button" class="btn btn-success btn-sm" @click.prevent="newModal">
                  <i class="fa fa-shopping-cart"></i>
                  Acquisti con voucher
                </button>
              </div>
            </div>
          </div>
          <div class="card-body table-responsive p-0">
            <b-container fluid>
              <!-- User Interface controls -->
              <b-row>
               <b-col lg="12" class="my-1">
                <b-form-group
                   label="Filtra per"
                   label-cols-sm="3"
                   label-align-sm="right"
                   label-size="sm"
                   description=""
                   class="mb-0">
                   <b-form-checkbox-group v-model="filterOn" class="mt-1">
                     <b-form-checkbox value="NomeRichiedente">Nome</b-form-checkbox>
                     <b-form-checkbox value="CognomeRichiedente">Cognome</b-form-checkbox>
                     <b-form-checkbox value="EmailRichiedente">Email</b-form-checkbox>
                     <b-form-checkbox value="TelefonoRichiedente">Telefono</b-form-checkbox>
                     <b-form-checkbox  v-if="$gate.Admin()" value="CodiceOrganizzatore">Parita iva</b-form-checkbox>
                     <b-form-checkbox value="Sigillo">Sigillo</b-form-checkbox>
                     <b-form-checkbox value="CodiceUtilizzo">Codice voucher</b-form-checkbox>
                   </b-form-checkbox-group>
                  </b-form-group>
                 </b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filter"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="5" md="6" class="my-1">
                  <b-form-group
                    label="Per page"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                show-empty
                small
                selectable
                stacked="md"
                ref="selectableTable"
                responsive="sm"
                :items="richieste"
                :fields="fields"
                @row-selected="onRowSelected"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
              >
              <template v-slot:cell(actions)="row">
                  <a href class="btn btn-primary btn-sm" @click.prevent="showModal(row.item)">
                    <i class="fas fa-info-circle"></i>
                  </a>
                </template>
                </b-table>
            </b-container>
          </div>
          <div align="center" v-if="totalRows > perPage && perPage != 0 ">
            <b-col sm="7" md="6" class="my-1" align="center">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="configModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="configModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Acquisto con voucher</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="passingValue()" id="myForm">
            <div class="modal-body">
              <div class="form-group">
                <label for="exampleFormControlInput1">Inserisci il codice voucher</label>
                <input
                  v-model="formVoucher.codice_voucher"
                  type="text"
                  id="codiceVoucher"
                  class="form-control"
                  name="codice_voucher"
                  placeholder="Codice voucher"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-success">Avvia l'acquisto</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isDisabled: function() {
      return !this.terms;
    }
  },
  data() {
    return {
      transProps: {
        name: "flip-list"
      },
      fields: [
        {
          key: "NomeRichiedente",
          label: "Nome",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "CognomeRichiedente",
          label: "Cognome",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "EmailRichiedente",
          label: "Email",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "TelefonoRichiedente",
          label: "Telefono",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "DataOraArchiviazione",
          label: "Archiviata il",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Dettagli" },
      ],
      totalRows: 1,
      totalRowsTitoli:1,
      richieste:{},
      currentPage: 1,
      perPage: 25,
      pageOptions: [50, 100, 150, { key: 0, text: "Tutti" }],
      sortBy: "nome",
      sortDesc: true,
      sortDirection: "asc",
      richiesteTitoli:{},
      provaVoucher:{},
      filter: null,
      dynamicValue: false,
      filterOn:{},
      /*filterOn: [
        "NomeRichiedente",
        "CognomeRichiedente",
        "TelefonoRichiedente",
        "EmailRichiedente",
        "TelefonoRichiedente",
        "DataOraArchiviazione"
      ],*/
      formVoucher: new Form({
        codice_voucher:"",
      }),
      formTitoli: new Form({
        ID_Richiesta: "",
        Sigillo: "",
        DataOraApprovazione: "",
        DataOraRifiuto: "",
        CodiceOrganizzatore: "",
        ID_Voucher: "",
      }),
      form: new Form({
        NomeRichiedente: "",
        CognomeRichiedente: "",
        EmailRichiedente: "",
        TelefonoRichiedente: "",
        DataOraArchiviazione: "",
        ID: "",
      })
    };
  },
  mounted() {
    // Set the initial number of items
    // console.log('pasoooooo component');
  },
  created() {
    if(user.Attivo==0){
      window.location.href = "/setPassword";
    }else{
      this.caricamentoRichieste();
    }
  },
  methods: {
    passingValue(){
      var codice = this.formVoucher.codice_voucher.trim();
      for(var i = 0;i<this.totalRows;i++){
        if(codice==this.richieste[i].CodiceUtilizzo){
            localStorage.setItem('code_voucher',codice);
            window.location.href="/buy_voucher";
             $('#configModal').modal('hide');
            return;
        }
      }
      swal.fire(
            'Voucher non trovato!',
            'Il codice non è presente nella lista.',
            'error'
          );
    },
    newModal() {
      this.formVoucher.codice_voucher = "";
      //$('#configModal').modal('show');
      $("#configModal").on('shown.bs.modal', function(){
        $(this).find('#codiceVoucher').focus();
      });
      $('#configModal').modal('show');
    },
    caricamentoRichieste(){
          this.form.get('api/richiesteArchiviate').then(({data})=>{
              this.richieste = data;
              this.totalRows = this.richieste.length;;
              }).catch(error=>{
        console.log("Errore nel carimento delle richieste "+error);
      })
      },
      showModal(richiesta){

        Vue.prototype.$idRichiestaArchivio = richiesta.ID;
        this.$router.push("dettagliArchiviate");
      },
      richiestaVoucher(){
          this.$Progress.start();
          this.formTitoli.get('api/richiesteVoucher').then(({data})=>{
            console.log("response "+data);
           // this.provaVoucher = data;
           // console.log("vediamo la risposta del server "+this.provaVoucher+"-- lenght "+this.provaVoucher.length+"--Esito "+this.provaVoucher[0].esito+"--Info "+this.provaVoucher[0].info);
           // this.$Progress.finish();
          }).catch(error=>{
            console.log("Vediamo se vado in errore nel voucher"+error);
              this.$Progress.fail();
          })
      },
      richiestaTitoli(id){
        this.formTitoli.get('api/richiesteTitoli/'+id).then(({data})=>{
          this.richiesteTitoli = data;
          this.richiesteTitoli.length = totalRowsTitoli;
          //console.log("sono nel posto giusto"+this.richiesteTitoli.length);
        }).catch(error=>{
        console.log("Errore nel carimento dei titoli "+error);
      })
      },
      onRowSelected(items) {
      this.selected = items;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  }
};
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Dettagli richiesta archiviata</h3>
            <div class="card-tools">
              <div class="side-by-side">
                  <button type="submit" class="btn btn-success btn-sm" v-if="$gate.Admin()" @click.prevent="showPhoto">Vedi foto</button>
              </div>
            </div>
          </div>
          <form>
            <div class="card-body">
              <b-container fluid>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Nome</label>
                      <input
                        v-model="formRichiesta.NomeRichiedente"
                        type="text"
                        class="form-control"
                        name="NomeRichiedente"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Cognome</label>
                      <input
                        v-model="formRichiesta.CognomeRichiedente"
                        type="text"
                        class="form-control"
                        name="TelefonoRichiedente"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Telefono</label>
                      <input
                        v-model="formRichiesta.TelefonoRichiedente"
                        type="text"
                        class="form-control"
                        name="TelefonoRichiedente"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Email</label>
                      <input
                        v-model="formRichiesta.EmailRichiedente"
                        type="text"
                        class="form-control"
                        name="EmailRichiedente"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Data ora creazione</label>
                      <input
                        v-model="formRichiesta.DataOraCreazione"
                        type="text"
                        class="form-control"
                        name="DataOraCreazione"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Data ora archiviazione</label>
                      <input
                        v-model="formRichiesta.DataOraArchiviazione"
                        type="text"
                        class="form-control"
                        name="DataOraArchiviazione"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                    <b-table striped hover :items="richiesteTitoli" :fields="fields" ref="table">
                      <template v-slot:cell(cartaceo)="row">
                        <div v-show="row.item.cartaceo==true">
                          <i class="fas fa-camera" style="color:#42A5F5"></i>
                      </div>
                      </template>
                      <template v-slot:cell(RettificaImporto)="row">
                        <div v-if="row.item.NotaRettificaImporto">
                          <i class="fas fa-info-circle" @click.prevent="showOpenEdit(row.item)"></i>
                        </div>
                      </template>
                    </b-table>
                </div>
                <div class="row">
                    <b-table striped hover :items="voucher" :fields="voucherFields" ref="tableVoucher">
                      <template v-slot:cell(actions)="row">
                        <div v-if="row.item.importoResiduo!=row.item.Importo">
                          <i class="fas fa-eye" @click.prevent="showHistory(row.item.ID)"></i>
                        </div>
                      </template>
                    </b-table>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="back">Indietro</button>
                  <button type="button" class="btn btn-primary" v-if="$gate.Admin()" data-dismiss="modal" @click.prevent="sendMail">Invia Mail</button>
                  <button type="button" class="btn btn-primary" v-if="$gate.Admin()" data-dismiss="modal" @click.prevent="generaPdf">Genera PDF</button>
                </div>
              </b-container>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="editOpenImporto"
      tabindex="-1"
      role="dialog"
      aria-labelledby="configModalTitle"
      aria-hidden="true"
      >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Modifica importo open</h5><br>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body">
            <!--<div class="checkbox">
              <label><input type="checkbox" id="mycheckbox" value="" @change="onChange($event)"> Rateo pari a 0.00€ (Se si è sicuri che la differenza sarà 0 per)</label>
            </div>-->
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">€</span>
              </div>
              <input type="number" class="form-control" v-model="importoOpen" aria-label="Amount (to the nearest dollar)" readonly>
            </div>
            <b-form-textarea
              id="textarea-state"
              v-model="testoOpen"
              rows="3"
              readonly
              ></b-form-textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Indietro</button>
          </div>
      </div>
    </div>
    </div>
    <div
      class="modal fade"
      id="showPhoto"
      tabindex="-1"
      role="dialog"
      aria-labelledby="configModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Galleria biglietti</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <div>
                <ul>
                  <li v-for="(msg,index) in pdf"><a
                    :href="msg"
                     :target="msg"
                    rel="noopener noreferrer">
                    pdf_{{index+1}}
                  </a></li>
                </ul>
              </div>
                <div class="widget-user-image" v-show="foto.length > 0">
                  <p v-show="foto.length > 1">
                      <a @click="prev"><i class="fas fa-arrow-left"></i></a> || <a @click="next"><i class="fas fa-arrow-right"></i></a>
                  </p>
                  <v-zoomer style="width: 100%; height: 100%; border: solid 1px silver;">
                    <img class="img-thumbnail" :src="foto[iFoto % foto.length]" alt="Foto_Cartaceo" id="myImg" style="object-fit: contain; width: 100%; height: 100%;">
                  </v-zoomer>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Indietro</button>
            </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalHistory"
      tabindex="-1"
      role="dialog"
      aria-labelledby="configModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Tabella storico</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <b-table striped hover :items="storico" :fields="historyTable" ref="table" responsive small>
            </b-table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="showPdf"
      tabindex="-1"
      role="dialog"
      aria-labelledby="configModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">PDF generator</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <div>
                <ul>
                  <li><a
                    :href="'Voucher2.pdf'"
                    :target="'Voucher2.pdf'"
                    rel="noopener noreferrer">PDF_generato</a></li>
                </ul>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Indietro</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isDisabled: function() {
      return !this.terms;
    }
  },
  data() {
    return {
      transProps: {
        name: "flip-list"
      },
      historyTable: [
        {
          key: "DataOraRegistrazione",
          label: "DataUtilizzo",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "parseImporto",
          label: "Importo",
          sortable: true,
          sortDirection: "desc"
        },
      ],
      voucherFields:[
        {
          key: "CodiceOrganizzatore",
          label: "Codice organizzatore",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "DataOraEmissione",
          label: "Data emissione",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "Importo",
          label: "importo",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "DataOraScadenza",
          label:"Data scadenza",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "CodiceUtilizzo",
          label:"Codice utilizzo",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "importoResiduo",
          label:"Importo residuo",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Vedi Acquisti" },
      ],
      fields: [
        {
          key: "Sigillo",
          label: "Sigillo",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "DataOraApprovazione",
          label: "Data approvazione",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "DataOraRifiuto",
          label: "Data rifiuto",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "CodiceOrganizzatore",
          label: "Organizzatore",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key:"cartaceo",
          label: "Dig/Cart",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "RettificaImporto",
          label: "Rettifica open",
          sortable: true,
          sortDirection: "desc"
        }
      ],
      totalRowsSigilli: 1,
      totalRowsTitoli: 1,
      richiesteTitoli:{},
      voucher:{},
      voucherUtilizzi:{},
      storico: {},
      contatore:0,
      richiesta:{},
      foto:[],
      appFoto:[],
      importoOpen:0,
      testoOpen:'',
      pdf:[],
      iFoto:0,
      formRichiesta:new Form({
        NomeRichiedente: "",
        CognomeRichiedente: "",
        EmailRichiedente: "",
        TelefonoRichiedente: "",
        DataOraCreazione : "",
        DataOraArchiviazione: "",
        ID: "",
        flag:"",
      }),
      formTitoli:new Form({
        ID: "",
        Sigillo: "",
        ID_Richiesta: "",
        CodiceOrganizzatore: "",
        DataOraApprovazione: "",
        DataOraRifiuto: "",
        ID_Voucher: ""
      }),
      formVouche:new Form({
        ID: '',
        ID_Voucher : "",
        DataOraEmissione: "",
        DataOraScadenza: "",
        Importo: "",
        storico_organizzatore:true,
        CodiceUtilizzo: ""
      }),
      formUtilizzi: new Form({
        ID:"",
        ID_Voucher:"",
        DataOraRegistrazione:"",
        Importo:""
      }),
    };
  },
  mounted() {
  },
  created() {
    if (typeof this.$idRichiestaArchivio !== 'undefined'){
        localStorage.setItem("idRichiestaArchiviata",this.$idRichiestaArchivio);
    }else{
      this.$idRichiestaArchivio = localStorage.getItem("idRichiestaArchiviata");
    }
      this.loadNewData();
  },
  methods: {
    next: function() {
            this.iFoto += 1;
        },
        prev: function() {
            this.iFoto -= 1;
        },
    back(){
      this.$router.push("archiviate");
    },
    showHistory(id_voucher){
      this.formVouche.ID_Voucher = id_voucher;
      this.formVouche.put('api/voucherUtilizzi/'+id_voucher).then(({data})=>{
        this.storico = data;
        $('#modalHistory').modal('show');
      }).catch(error=>{
        console.log("Errore nel carimento delle richieste "+error);
      });
    },
    showPhoto(){
      this.foto=[];
      this.appFoto=[];
      this.pdf=[];
      this.formRichiesta.contatoreFoto = this.contFoto;
      this.formRichiesta.post('api/richiesteArchiviate/').then(({data})=>{
        if(data.length === 0){
          swal.fire(
                  "Foto error!",
                  "In questa richiesta non sono stati trovati titoli cartacei!",
                  "error"
                );
        }else{
          this.appFoto = data;
          for(let i = 0;i<this.appFoto.length;i++){
            let stringa = this.appFoto[i].split(".");
            let lunghezza = stringa.length;
            let estensione = stringa[lunghezza-1];
            if(estensione=='pdf' || estensione=='PDF'){
              this.pdf.push(this.appFoto[i]);
            }else{
              this.foto.push(this.appFoto[i]);
            }
          }
          $("#showPhoto").modal("show");
        }
        }).catch(error=>{
          console.log("Errore nel carimento delle foto "+error);
        });
    },
    loadNewData(){
        this.loadRichiesta();
        this.loadTitoli();
        this.loadVocuher();
    },
    showOpenEdit(sigillo){
      if(sigillo.RettificaImporto == .00){
        this.importoOpen=0.00;
      }else{
        this.importoOpen=sigillo.RettificaImporto;
      }
      this.testoOpen=sigillo.NotaRettificaImporto;
      $("#editOpenImporto").modal("show");
    },
    sendMail(){
      this.formRichiesta.flag=true;
      this.formRichiesta.post('api/richiesteTitoli/').then(({data})=>{
        let respo = data;
        if(respo=='sigilliAccettati'){
          swal.fire({
            title:"Iniviata!",
            text:"Email inviata!",
            type:"success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then(result=>{
             window.location.href = "/archiviate";
          });
        }else{
          swal.fire(
                  "Mail error!",
                  "La richiesta non contiene sigilli accettati!",
                  "error"
                );
        }
      }).catch(error=>{
        console.log("Errore nel carimento della richiesta "+error);
      });
    },
    generaPdf(){
      this.formRichiesta.flag=false;
      this.formRichiesta.post('api/richiesteTitoli/').then(({data})=>{
        let respo = data;
        if(respo=='sigilliAccettati'){
          swal.fire({
            title:"PDF success!",
            text:"PDF generato!",
            type:"success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then(result=>{
            $("#showPdf").modal("show");
          });
        }else{
          swal.fire(
                  "PDF error!",
                  "La richiesta non contiene sigilli accettati!",
                  "error"
                );
        }
      }).catch(error=>{
        console.log("Errore nel carimento della richiesta "+error);
      });
    },
    loadRichiesta(){
        this.formRichiesta.get('api/richiesteSospese/'+this.$idRichiestaArchivio).then(({data})=>{
        this.richiesta = data;
        this.formRichiesta.fill(this.richiesta[0]);
              }).catch(error=>{
        console.log("Errore nel carimento della richiesta "+error);
      });
    },
    loadVocuher(){
      let cont=0;
          this.formVouche.get('api/richiesteVoucher/'+this.$idRichiestaArchivio).then(({data})=>{
            this.voucher = data;
          }).catch(error=>{
            console.log("Errore nel caricamento voucher"+error);
          });
    },
    loadTitoli(){
      this.richiesteTitoli = {};
        this.formTitoli.get('api/richiesteTitoli/'+this.$idRichiestaArchivio).then(({data})=>{
            this.richiesteTitoli=data;
            this.totalRowsTitoli = this.richiesteTitoli.length;
        }).catch(error=>{
        console.log("Errore nel carimento della richiesta titoli "+error);
      });
    },
  }
}

</script>
